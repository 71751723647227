import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Plangroups } from '@models/partner/partner-services';
import { PartnerServicesWebservice } from '@webservices/obendy/partner-services-webservice/partner-services-webservice.service';
import { Observable, filter, of, switchMap } from 'rxjs';

export function planGroupsResolver(route: ActivatedRouteSnapshot): Observable<Plangroups> {
  const partnerServicesWebservice = inject(PartnerServicesWebservice);

  const partnerId$ = of(route.paramMap.get('partnerId')).pipe(filter(Boolean));

  return partnerId$.pipe(switchMap((partnerId) => partnerServicesWebservice.getPlanGroups(partnerId)));
}
