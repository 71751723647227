import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { PreviewValues } from '@models/partner/partner-services';
import { PartnerServicesWebservice } from '@webservices/obendy/partner-services-webservice/partner-services-webservice.service';
import { Observable, combineLatest, filter, map, of, switchMap } from 'rxjs';

export function previewValuesResolver(route: ActivatedRouteSnapshot): Observable<PreviewValues> {
  const partnerServicesWebservice = inject(PartnerServicesWebservice);
  const partnerId$ = of(route.paramMap.get('partnerId')).pipe(filter(Boolean));
  const serviceId$ = of(route.paramMap.get('serviceId')).pipe(filter(Boolean));

  return combineLatest([partnerId$, serviceId$]).pipe(
    switchMap(([partnerId, serviceId]) => partnerServicesWebservice.getConsentsLabelKeys({ partnerId, serviceId })),
    map((labelKeys) => labelKeys.reduce((acc, current) => ({ ...acc, [current.name]: current }), {}) as PreviewValues)
  );
}
