import { canActivateBenefitRoutes } from './guards/benefit.guard';
import { Route } from '@angular/router';
import { DeactivationGuarded } from '@commons/services/can-deactivate-confirmation.service';
import {
  SERVICES_ROUTING,
  benefitResolver,
  displayConfiguration2Resolver,
  displayConfigurationFromCatalogResolver,
  lifeMomentsResolver,
  planGroupsResolver,
  planGroupsToEditResolver,
  previewValuesResolver,
  serviceConsentsResolver,
  servicePlanGroupsConfigurationResolver,
  serviceTabResolver,
  serviceTabsResolver,
} from '@features/partners/builder/content/services/routes';
import { defaultServiceTab } from '@features/services/routes/services.routes';
import { Observable } from 'rxjs';

export const SERVICES_ROUTES: Route[] = [
  {
    path: SERVICES_ROUTING.list,
    title: 'Les services',
    loadComponent: async () =>
      (await import('@features/partners/builder/content/services/services-list/services-list.component'))
        .ServicesListComponent,
    resolve: {
      planGroups: planGroupsResolver,
    },
  },
  {
    path: `${SERVICES_ROUTING.benefit}/:serviceId`,
    title: 'Avantage',
    loadComponent: async () =>
      (await import('@features/partners/builder/content/services/service-benefit/service-benefit.component'))
        .ServiceBenefitComponent,
    canActivate: [canActivateBenefitRoutes],
    resolve: { benefit: benefitResolver },
  },
  {
    path: SERVICES_ROUTING.consent,
    children: [
      {
        path: `:serviceId`,
        title: 'Consentement',
        resolve: {
          consents: serviceConsentsResolver,
          previewValues: previewValuesResolver,
        },
        loadComponent: async () =>
          (await import('@features/partners/builder/content/services/service-consents/service-consents.component'))
            .ServiceConsentsComponent,
        canDeactivate: [(comp: DeactivationGuarded): Observable<boolean> => comp.canDeactivate()],
      },
    ],
  },
  {
    path: SERVICES_ROUTING.tabs,
    children: [
      {
        path: `:serviceId`,
        title: 'Gestion des onglets',
        resolve: { serviceTabs: serviceTabsResolver },
        loadComponent: async () =>
          (await import('@features/partners/builder/content/services/service-tabs/service-tabs.component'))
            .ServiceTabsComponent,
      },
      {
        path: `:serviceId/${SERVICES_ROUTING.create}`,
        title: "Création d'un onglet",
        loadComponent: async () =>
          (await import('@features/partners/builder/content/services/service-tab/service-tab.component'))
            .ServiceTabComponent,
        canDeactivate: [(comp: DeactivationGuarded): Observable<boolean> => comp.canDeactivate()],
        data: {
          tab: defaultServiceTab,
        },
      },
      {
        path: ':serviceId/:tabId',
        title: "Modification d'un onglet",
        loadComponent: async () =>
          (await import('@features/partners/builder/content/services/service-tab/service-tab.component'))
            .ServiceTabComponent,
        canDeactivate: [(comp: DeactivationGuarded): Observable<boolean> => comp.canDeactivate()],
        resolve: { tab: serviceTabResolver },
      },
    ],
  },
  {
    path: SERVICES_ROUTING.displayConfig,
    resolve: {
      lifeMoments: lifeMomentsResolver,
    },
    children: [
      {
        path: `${SERVICES_ROUTING.create}/:serviceId`,
        title: "Création d'un service",
        loadComponent: async () =>
          (
            await import(
              '@features/partners/builder/content/services/service-display-configuration/service-display-configuration.component'
            )
          ).ServiceDisplayConfigurationComponent,
        data: {
          isInCreationMode: true,
        },
        resolve: {
          displayConfiguration: displayConfigurationFromCatalogResolver,
          servicePlanGroupsConfiguration: servicePlanGroupsConfigurationResolver,
          planGroupsToEdit: planGroupsToEditResolver,
        },
        canDeactivate: [(comp: DeactivationGuarded): Observable<boolean> => comp.canDeactivate()],
      },
      {
        title: "Modification d'un service",
        path: `:serviceId`,
        data: {
          isInCreationMode: false,
        },
        resolve: {
          displayConfiguration: displayConfiguration2Resolver,
          servicePlanGroupsConfiguration: servicePlanGroupsConfigurationResolver,
          planGroupsToEdit: planGroupsToEditResolver,
        },
        canDeactivate: [(comp: DeactivationGuarded): Observable<boolean> => comp.canDeactivate()],
        loadComponent: async () =>
          (
            await import(
              '@features/partners/builder/content/services/service-display-configuration/service-display-configuration.component'
            )
          ).ServiceDisplayConfigurationComponent,
      },
    ],
  },
];
