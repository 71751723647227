import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';
import { BenefitService } from '@features/partners/builder/content/services/service-benefit/services/benefit.service';

export const canActivateBenefitRoutes: CanActivateFn = ({ paramMap }: ActivatedRouteSnapshot): boolean | UrlTree => {
  const partnerId = paramMap.get('partnerId');
  const serviceId = paramMap.get('serviceId');

  if (partnerId === null || serviceId === null) {
    return inject(Router).createUrlTree(['/']);
  }

  const benefitService = inject(BenefitService);

  benefitService.setPartnerId(partnerId);
  benefitService.setServiceId(serviceId);

  return true;
};
