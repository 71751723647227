import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { DisplayConfigurationResponse2 } from '@models/partner/partner-services/display-configuration/display-configuration-response-2';
import { PartnerServicesWebservice } from '@webservices/obendy/partner-services-webservice/partner-services-webservice.service';
import { Observable, combineLatest, filter, of, switchMap } from 'rxjs';

export function displayConfiguration2Resolver(
  route: ActivatedRouteSnapshot
): Observable<DisplayConfigurationResponse2> {
  const webservicesService = inject(PartnerServicesWebservice);
  const partnerId$ = of(route.paramMap.get('partnerId')).pipe(filter(Boolean));
  const serviceId$ = of(route.paramMap.get('serviceId')).pipe(filter(Boolean));

  return combineLatest([partnerId$, serviceId$]).pipe(
    switchMap(([partnerId, serviceId]) =>
      webservicesService.readDisplayConfigurationResponse2({ partnerId, serviceId })
    )
  );
}
