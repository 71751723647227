import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { PartnerServicesWebservice } from '@webservices/obendy/partner-services-webservice/partner-services-webservice.service';

export function serviceTabsResolver({ params }: ActivatedRouteSnapshot) {
  return inject(PartnerServicesWebservice).getTabs(params['partnerId'], params['serviceId']);
}

export function serviceTabResolver({ params }: ActivatedRouteSnapshot) {
  return inject(PartnerServicesWebservice).getTab(params['partnerId'], params['serviceId'], params['tabId']);
}
