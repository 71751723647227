import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ServiceFromCatalogService } from '@features/partners/builder/content/services/providers/service-from-catalog.service';
import { DisplayConfigurationResponse2 } from '@models/partner/partner-services/display-configuration/display-configuration-response-2';
import { Observable, filter, of, switchMap } from 'rxjs';

export function displayConfigurationFromCatalogResolver({
  paramMap,
}: ActivatedRouteSnapshot): Observable<DisplayConfigurationResponse2> {
  const serviceId$ = of(paramMap.get('serviceId')).pipe(filter(Boolean));
  const serviceFromCatalogService = inject(ServiceFromCatalogService);

  return serviceId$.pipe(
    switchMap((serviceId) => serviceFromCatalogService.readDisplayConfigurationFromCatalog(serviceId))
  );
}
