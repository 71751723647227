import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import type { LifeMoment } from '@models/life-moment';
import { LifeMomentsWebservice } from '@webservices/life-moments/life-moments.webservice';
import { Observable, filter, of, switchMap } from 'rxjs';

export function lifeMomentsResolver(route: ActivatedRouteSnapshot): Observable<LifeMoment[]> {
  const lifeMomentsWebservice = inject(LifeMomentsWebservice);
  const partnerId$ = of(route.paramMap.get('partnerId')).pipe(filter(Boolean));

  return partnerId$.pipe(switchMap((partnerId) => lifeMomentsWebservice.getPartnerLifeMoments(partnerId)));
}
